h5 {
  margin: 0.82rem 0 0.656rem 0;
}

.col .row {
  margin-left: 0;
  margin-right: 0;
}

.clearfix {
  clear: both;
}

.input-field > label {
  transform: initial;
}
