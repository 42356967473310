thead {
  border-bottom: 1px solid #d0d0d0;
}

tr {
  border-bottom: none;
}

.grid-table .grid-header-title {
  color: #000;
}
