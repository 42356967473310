@use "fonts";
@use "input";
@use "button";
@use "table";
@use "spacing";
@use "materialize";
@use "nav";
@use "card";
@use "sweetalert";
@use "nprogress";
@use "datepicker";
@use "helpers";

html,
body {
  height: 100%;
}

html {
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}

textarea {
  border: 1px solid #9e9e9e;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
  background-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

body.login-page {
  background: url("../assets/img/bannersistematecnico.jpg") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
}

body {
  background: #f4f4f4;
}

.active {
  font-weight: bold;
}

.container .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.z-depth-1,
nav,
.card-panel,
.card,
.toast,
.btn,
.btn-large,
.btn-floating,
.dropdown-content,
.collapsible,
.side-nav {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
    0 3px 1px -2px rgb(0 0 0 / 20%);
}

.boxCalendario {
  max-width: 318px;
}

.fa.mgleft5 {
  margin-left: 5px;
}

span.required:after {
  content: " * ";
  color: #b91717;
}
