button.link {
  text-decoration: underline;
  background: transparent;
  border: none;
  padding: 0;

  &.no-decoration {
    text-decoration: none;
  }

  &.blue-text {
    color: #039be5;
  }
}

button.btn-flat {
  box-shadow: none;
  background-color: transparent;
  color: #343434;
  cursor: pointer;
  transition: background-color 0.2s;

  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
}
