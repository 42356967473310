.card {
  .card-content {
    padding: 20px;
    border-radius: 0 0 2px 2px;

    .card-title {
      font-size: 24px;
      font-weight: 300;
      line-height: 48px;
      margin-bottom: 0;
    }
  }

  .card-action {
    padding: 20px;
  }
}

.card-panel {
  transition: box-shadow 0.25s;
  padding: 20px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
}
