input:not([type]),
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="time"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="tel"],
input[type="number"],
input[type="search"],
textarea.materialize-textarea {
  margin: 0 0 20px 0 !important;
  font-size: 1rem !important;
}

input:not([type]) + label:after,
input[type="text"] + label:after,
input[type="password"] + label:after,
input[type="email"] + label:after,
input[type="url"] + label:after,
input[type="time"] + label:after,
input[type="date"] + label:after,
input[type="datetime"] + label:after,
input[type="datetime-local"] + label:after,
input[type="tel"] + label:after,
input[type="number"] + label:after,
input[type="search"] + label:after,
textarea.materialize-textarea + label:after {
  top: 60px !important;
}

input.invalid:not([type]),
input.invalid:not([type]):focus,
input[type="text"].invalid:not(.browser-default),
input[type="text"].invalid:not(.browser-default):focus,
input[type="password"].invalid:not(.browser-default),
input[type="password"].invalid:not(.browser-default):focus,
input[type="email"].invalid:not(.browser-default),
input[type="email"].invalid:not(.browser-default):focus,
input[type="url"].invalid:not(.browser-default),
input[type="url"].invalid:not(.browser-default):focus,
input[type="time"].invalid:not(.browser-default),
input[type="time"].invalid:not(.browser-default):focus,
input[type="date"].invalid:not(.browser-default),
input[type="date"].invalid:not(.browser-default):focus,
input[type="datetime"].invalid:not(.browser-default),
input[type="datetime"].invalid:not(.browser-default):focus,
input[type="datetime-local"].invalid:not(.browser-default),
input[type="datetime-local"].invalid:not(.browser-default):focus,
input[type="tel"].invalid:not(.browser-default),
input[type="tel"].invalid:not(.browser-default):focus,
input[type="number"].invalid:not(.browser-default),
input[type="number"].invalid:not(.browser-default):focus,
input[type="search"].invalid:not(.browser-default),
input[type="search"].invalid:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus,
.select-wrapper.invalid > input.select-dropdown {
  box-shadow: none !important;
}

.input-field {
  margin-bottom: 0;

  label {
    color: #9e9e9e;
    position: absolute;
    top: 0.8rem;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: 0.2s ease-out;

    &.active {
      font-size: 0.8rem !important;
      -webkit-transform: translateY(-140%) !important;
      transform: translateY(-140%) !important;
    }
  }
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  left: -9999px;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: 0.28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: 0.28s ease;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 2px solid #5a5a5a;
}

[type="radio"]:checked + label:before {
  border: 2px solid transparent;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid #26a69a;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #26a69a;
}

[type="radio"]:checked + label:after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
