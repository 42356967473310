#nprogress {
  pointer-events: none;

  .bar {
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #fff;

    .peg {
      display: block;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px #26a69a, 0 0 5px #26a69a;
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0, -4px);
      -ms-transform: rotate(3deg) translate(0, -4px);
      transform: rotate(3deg) translate(0, -4px);
    }
  }

  .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 10px;
    right: 15px;

    .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: #ffffff;
      border-left-color: #ffffff;
      border-radius: 50%;
      -webkit-animation: nprogress-spinner 400ms linear infinite;
      animation: nprogress-spinner 400ms linear infinite;
    }
  }
}

body.login-page #nprogress {
  .bar {
    background: #26a69a;
  }
  .spinner .spinner-icon {
    border-top-color: #26a69a;
    border-left-color: #26a69a;
  }
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
