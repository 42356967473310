.react-datepicker {
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.react-datepicker__header {
  background-color: transparent;
}

.react-datepicker__day-name {
  font-size: 6pt;
}

.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.49rem;
  line-height: 2.2rem;
  margin: 0;
  padding: 0.166rem;
}

.react-datepicker__day-name {
  width: 2.19rem;
  height: 12px;
  padding: 5px 0;
  text-transform: uppercase;
}

.react-datepicker__current-month {
  text-transform: capitalize;
}

.react-datepicker__navigation {
  line-height: 18px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected:hover {
  color: #000;
  background: transparent;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted,
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  border-radius: 0;
  background-color: #fdf2c5;
  color: #000;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border-radius: 0;
  background: #00acc1;
  color: #fff;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
  background: #00acc1;
  color: #fff;
}

.react-datepicker-popper{
  z-index: 999999;
}